* {margin: 0;padding: 0;box-sizing: border-box;}
body {float: left;width: 100%;box-sizing: border-box;position: relative;background-color: #EFEEEC;}
.wrapper {float: left;width: 100%;overflow: hidden;}
.Toastify__close-button--light {width: auto;}
.btn {
  border-radius: 0px;
}
.navbarHeader .fulllogo {
width: 180px;
}
.navbarHeader .smallLogo {
width: 30px;
}
.adminmain {
float: left;
width: 100%;
position: relative;
height: 100vh;
display: flex;
}
.logoheader img {
width: 210px;
}
.panelwrapper {
float: left;
/* width: calc(100% - 20%); */
height: 100vh;
width: 100%;

position: relative;
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
textarea.form-control{font-size: 13px;}
.panelwrapper.active {
/* width: calc(100% - 5%); */
width: 100%;
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}

/* login page css start here */
.loginWrapper {
float: left;
width: 100%;
position: relative;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.logoheader {
color: #c0422f;
font-weight: 700;
letter-spacing: 0.8px;
margin: 0 0 14px;
font-size: 39px;
text-align: center;
}
.loginMainblog {
float: left;
width: 400px;
padding: 25px;
background-color: #fff;
}
.loginMainblog h4 {
float: left;
width: 100%;
font-size: 20px;
margin: 0 0 15px;
}
.formloginset {
float: left;
width: 100%;
}
.mainloginwrps {
float: left;
width: 100%;
height: 100%;
}
.formloginset .form-label {
float: left;
width: 100%;
font-size: 14px;
}
.mb-3 {
  float: left;
  width: 100%;
}
.formloginset .editable{display: inline-flex;align-items: center;padding-left: 30px;position: relative;margin: 0px;}
.formloginset .editable input {height: 20px!important;width: 20px;position: absolute;left: 0px;margin: 0px!important;}
.formloginset input:not(.form-check-input) {
border-radius: 0px;
height: 40px;
font-size: 13px;
box-shadow: none !important;
}
.widthfullbtn {
width: 100%;
}
.formloginset select {
width: 100%;
border-radius: 0px;
height: 40px;
font-size: 13px;
padding: 10px 10px;
border-color: #ced4da;
outline: none;
box-shadow: none !important;
background-image: url("../img/caret-down.svg");
background-size: 10px;
background-repeat: no-repeat;
background-position: calc(100% - 12px) center;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
small.note {
font-size: 13px;
margin: 5px 0 0;
color: #7a7a7a;
}
.formloginset .btn {
background-color: #c0422f;
border-color: #c0422f;
border-radius: 0px;
padding: 8px 10px;
margin: 5px 0 0;
width: 100%;
position: relative;
overflow: hidden;
z-index: 9;
}
.signInbtn::after {float: left;width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: -1;content: "";background-color: #333;border-color: #333;transform: translateY(100%);-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.signInbtn:hover::after {transform: translateY(0%);-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.passwordfiled {
float: left;
width: 100%;
position: relative;
}
.passwordfiled input {
padding-right: 35px;
}
.passwordfiled .showpassbtn {
width: 18px;
height: 18px;
position: absolute;
right: 9px;
top: 8px;
cursor: pointer;
}
.passwordfiled .showpassbtn svg {
float: left;
width: 100%;
}
.danger-colorset {
font-size: 12px;
color: #F84646;
float: left;
width: 100%;
margin: 4px 0 0;
}
/* login page css end here */

/* sidebar css start here */
.sidebar {
flex-basis: 20%;
float: left;
width: 100%;
height: 100vh;
background-color: #fff;
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
.navbarHeader {
float: left;
width: 100%;
height: 71px;
padding: 22px 25px;
border-bottom: 1px solid #EFEEEC;
border-right: 1px solid #EFEEEC;
}
.navbarHeader h1 {
margin: 0px;
float: left;
width: auto;
font-size: 23px;
}
.sidebar .nav .nav-link {
display: flex;
justify-content: flex-start;
align-items: center;
color: #333;
padding: 14px 5px;
font-size: 14px;
height: 50px;
cursor: pointer;
}
.sidebar.active {
flex-basis: 5%;
width: 5%;
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
.sidebar.active .navbarHeader {
text-align: center;
padding: 22px 18px;
}
.sidebar.active .navbarHeader h1 {
width: 100%;
}
.sidebar .nav .nav-link span{
display: inline;
}
.sidebar.active .nav-link span {
display: none;
}
.sidebar.active .nav .nav-link img {
margin: 0px auto;
width: 21px;
height: 21px;
}
.sidebar .nav .nav-link img {
width: 19px;
height: 19px;
object-fit: contain;
object-position: center;
margin: 0 12px 0px 0px;
}
.sidebar .nav .nav-link:hover, .sidebar .nav .nav-link.active {
/* background: #F3F1FA; */
/* color: #464a53; */
color: #c0422f;
border: 1px solid #c0422f;
border-radius: 5px;
/* background-color: transparent;
color: #c0422f; */
}
.sidebar .nav .nav-link:hover, .sidebar .nav .nav-link.active img {
    filter: invert(34%) sepia(70%) saturate(1297%) hue-rotate(336deg) brightness(81%) contrast(93%);;
}

/* sidebar css end here */


/* header css start here */
.headeradmin {
float: left;
width: 100%;
height: 71px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 15.6px 20px;
background-color: #fff;
}
.userdropdown {
float: left;
width: auto;
}
.userdropdown .btn, .userdropdown .btn:focus {
padding: 0px;
background: transparent;
border: 0px;
color: #333;
display: flex;
justify-content: center;
align-items: center;
}
.userdropdown .btn img {
width: 32px;
height: 32px;
object-fit: cover;
margin: 0 8px 0px 0px;
}
.toglebutton {
width: 21.63px;
float: left;
height: 21.63px;
padding: 0px;
border: 0px;
background: transparent;
display: flex;
justify-content: center;
align-items: center;
}
.toglebutton img {
width: 100% !important;
height: 100% !important;
}
/* header css end here */

/* page common content css start here */
.panelContentwrapper {
float: left;
height: calc(100vh - 95px);
width: calc(100% - 25px);
background-color: #fff;
position: relative;
margin: 12px;
padding: 15px;
}
.pagetitle {
flex-grow: 1;
margin: 0px 0px 0px 15px;
}
.tableContent {
float: left;
width: 100%;
height: 100%;
position: relative;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.ScrollbarsWrapper .view {
  float: left;
  width: 100%;
}
.ScrollbarsWrapper .view::-webkit-scrollbar, .ScrollbarsSidebar .view::-webkit-scrollbar {
display: none;
}
.ScrollbarsWrapper .view, .ScrollbarsSidebar .view {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
margin-bottom: auto !important;
}
.ScrollbarsSidebar .view{
margin-bottom: auto !important;
width: 100%;
}
/* page common content css end here */

/* dashboard css start here */
.dashboardBlogs {
  float: left;
  width: 32.33%;
  position: relative;
  color: #fff !important;
  text-decoration: none !important;
  padding: 15px 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  -moz-page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  -o-column-break-inside: avoid;
  -ms-column-break-inside: avoid;
  column-break-inside: avoid;
  page-break-inside: avoid;
}
.dashboardBlogs h5 {
font-size: 15px;
margin: 0 0 10px;
}
.dashboardBlogs h1 {
font-weight: 600;
font-size: 37px;
}
.gradient-1 {background-image: linear-gradient(230deg, #759bff, #843cf6);}
.gradient-2 {background-image: linear-gradient(230deg, #fbaaa2, #fc5286);}
.gradient-3 {background-image: linear-gradient(230deg, #ffc480, #ff763b);}
.gradient-4 {background-image: linear-gradient(230deg, #6a8eff, #0e4cfd);}
.gradient-5 {background-image: linear-gradient(230deg, #759bff, #843cf6);}
.gradient-6 {background-image: linear-gradient(230deg,#ffcf33, #b28900);}
.gradient-7 {background-image: linear-gradient(230deg, #6fbf73, #357a38);}
.gradient-8 {background-image: linear-gradient(230deg, #ff5722, #b23c17);}

.dashboardcols {
float: left;
width: 100%;
height: 100%;
position: relative;
-webkit-column-count: 4;
column-count: 4;
-webkit-column-gap: 12px;
column-gap: 12px;
display: flex;
flex-flow: wrap;
justify-content: flex-start;
}
.dashboardBlogs img {
width: 50px;
height: 50px;
position: absolute;
right: 30px;
top: 30px;
object-fit: contain;
object-position: center;
filter: invert(91%) sepia(67%) saturate(1438%) hue-rotate(181deg) brightness(113%) contrast(95%);
}
.userprofile {
float: left;
width: 45px;
height: 45px;
object-fit: contain;
object-position: center;
background-color: #f7f7f7;
border-radius: 4px;
}
/* dashboard css end here */

/* table custome css start here */  
.panelContentwrapper .table {
border: 1px solid #eee;
border-radius: 6px;
}
.panelContentwrapper .table thead, .panelContentwrapper .table tr, tbody, td, tfoot, th, thead, tr {
float: none;
width: auto;
}
.table-responsive{
overflow-x:auto;
-webkit-overflow-scrolling:touch
}
.panelContentwrapper .table thead th {
background-color: #eee;
font-size: 13px;
padding: 12px 12px;
font-weight: 500;
}
.panelContentwrapper .table tbody td {
font-size: 13px;
padding: 9px 12px;
vertical-align: middle;
}
.panelContentwrapper .table tbody tr:hover, .panelContentwrapper .table tr.active {
background-color: #F3F3F9;
}
.tablecollapsewraps {
    background-color: transparent !important;
}
.tablecollapsewraps table th {
background-color: #f8f8f8;
font-size: 12px;
padding: 10px 12px;
font-weight: 500;
}
.tablecollapsewraps table td {
font-size: 12px;
}
.actionbtn {
float: left;
width: 17px;
height: 17px;
padding: 0px;
border: 0px;
outline: none !important;
box-shadow: none;
background: transparent;
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
.actionbtn img {
float: left;
height: 100%;
width: 100%;
}
.actionbtn.active img {
transform: rotate(180deg);
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
.panelContentwrapper .table tbody .actionbtn {
margin-right: 12px;
}
.panelContentwrapper .table tbody .actionbtn:last-child {
margin-right: 0px;
}
.badge.bg-primary {
background-color: rgb(47,179,68, 0.2) !important;
color: #0ca678;
letter-spacing: 0.5px;
font-weight: 500;
font-size: 10px;
}
.badge.bg-danger {
background-color: rgb(214,57,57, 0.2) !important;
color: #c13333;
letter-spacing: 0.5px;
font-weight: 500;
font-size: 10px;
}
.switchbtn {
display: flex;
width: auto;
align-items: center;
justify-content: flex-start;
}
.switchbtn .form-switch {
font-size: 17px;
}
.card-header-New {
float: left;
margin: 0 0 15px;
width: 100%;
}
.searchBoxwithbtn {
float: left;
position: relative;
/* width: 260px; */
}
.modal-preview-new .modal-dialog {
  max-width: 50%;
}
.searchBoxwithbtn .form-control {
border: 1px solid #ddd;
border-radius: 0px !important;
box-shadow: none!important;
font-size: 13px;
height: 36px;
padding: 7px 35px 7px 10px;
}
.searchBoxwithbtn .icon-tabler-search {
position: absolute;
right: 9px;
top: 6px;
width: 19px;
}
.searchBoxwithbtn .searchclear {
cursor: pointer;
height: 13px;
position: absolute;
right: 9px;
top: 11px;
width: 13px;
}
.card-header-right {
float: right;
width: auto;
}
.card-header-right select:first-child {
margin-right: 10px;
}

.card-header-right select {
box-shadow: none!important;
}
.activestatusselet, .userLimitselect {
background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);
background-position: calc(100% - 6px);
background-repeat: no-repeat;
background-size: 13px;
border: 1px solid #ddd!important;
cursor: pointer;
float: left;
width: 95px; 
 /* //90 to 95 */
height: 36px;
font-size: 14px;
border-radius: 0px;
}
/* table custome css end here */  
.ExportModel_wrapper{
  display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    flex-direction: column;
}
/* common modal css start here */
.commonModal .modal-header .modal-title.h4 {text-align: center;font-size: 22px;}
.commonModal .modal-footer {flex-direction: row;flex-wrap: nowrap;align-items: center;justify-content: center;}
.commonModal .modal-footer .btn {text-transform: capitalize;width: auto;min-width: 100px;}
.commonModal .modal-footer .btn.btn-primary {background-color: #c0422f;border-color: #c0422f;}
.tabheadebuttns .btn.btn-primary, .button-color .btn.btn-primary {background-color: #c0422f;border: 0px;}
.commonModal .modal-dialog {float: none;display: table;width: 420px;}
.notformsection {
float: left;
width: 100%;
text-align: center;
}
.formsectionset {
  float: left;
  width: 100%;
  position: relative;
}
.notformsection p {
font-size: 17px;
margin: 0 0 10px;
color: #333;
}
.notformsection h5 {
font-size: 17px;
margin: 0px;
}
/* common modal css end here */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* country code start here */
.selectNoInputField {float: left;width: 100%;border: 1px solid #E0E0E5;position: relative;display: flex;}
.selectNoInputField input {height: 40px;border: 0px;padding: 10px 10px;font-size: 13px;outline: none;width: 100%;}
.selectNoInputField .inputBoxSelect {width: 100px;text-align: left;margin: 0px;position: relative;padding-left: 10px;}
.selectNoInputField .inputBoxSelect::after{
  content: "";
  float: left;
  width: 1px;
  height: 20px;
  background-color: #c1c1c1;
  position: absolute;
  right: 0;
  top: 11px;
}
.form-group.error .selectNoInputField {border-color: #F84646;}
.form-group.error .form-groupfiled.expirydatswrapper .SiXSY, .form-group.error .form-groupfiled.expirydatswrapper .iqDxdu {border-color: #F84646 !important;}
.countselet {font-size: 12px;float: left;width: auto;margin: 0 1px 0 0;color: #333;}
.flagsDropdown {
float: left;
width: 220px;
margin: 0px;
padding: 0px;
position: absolute !important;
z-index: 9;
background-color: #fff;
box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
left: 0;
bottom: 51px;
top: auto;
border: 0px;
}
.flagsDropdownMain a span {
float: left;
width: 80%;
text-overflow: ellipsis;
overflow: hidden;
}
.flagsDropdownMain {
float: left;
width: 100%;
padding: 0px;
margin: 0px;
max-height: 150px;
overflow: auto;
}
.flagsDropdownMain a {
float: left;
width: 100%;
list-style: none;
display: flex;
padding: 10px 13px;
font-size: 12px;
cursor: pointer;
text-align: left;
vertical-align: middle;
align-items: center;
}
.flagsDropdownMain a:hover {
background-color: #eee;
}
.flagsDropdownMain a img {
width: 26px;
margin: 0px 8px 0px 0px;
}
.selectNoInputField .inputBoxSelect .selectEmojis {
padding: 0px;
border: 0px;
height: 100%;
width: 100%;
display: flex;
position: relative;
align-items: center;
text-align: center;
justify-content: flex-start;
background-color: transparent;
margin: 0px;
}
.selectNoInputField .inputBoxSelect .selectEmojis .flagsimg {
width: 20px;
height: 20px;
border-radius: 100%;
margin: 0px 7px 0px 0px;
object-fit: cover;
}
.emailset {
}
.noteemail {
color: #9c9c9c;
font-size: 15px;
}
.selectNoInputField .inputBoxSelect .selectEmojis .arrowdownimg {
width: 7px;
margin: 0px 0px 0px 3px;
}
.btn.formcomnbtn {
background-color: #1D1D1D;
border-color: #1D1D1D;
width: 100%;
padding: 11px 45px;
float: right;
font-size: 16px;
color: #cecece;
border-radius: 0;
}
/* country code end here */

/* common tabs section start here */
.tabcomnsection {
float: left;
width: 100%;
border-bottom: 1px solid #ced4da;
padding: 0px;
margin: 0px 0px 15px;
position: relative;
display: flex;
justify-content: flex-start;
}
.tabcomnsection li.active {
font-weight: 500;
}
.tabcomnsection li {
width: auto;
float: left;
list-style: none;
padding: 0px 20px 10px 0px;
line-height: normal;
font-size: 15px;
cursor: pointer;
position: relative;
}
.tabcomnsection li::after {
content: "";
float: left;
width: calc(100% - 20px);
height: 3px;
background-color: transparent;
position: absolute;
left: 0;
bottom: 0;
}
.tabcomnsection li.active::after {
background-color: #c0422f;
}
.tabsWrapper {
float: left;
width: 100%;
position: relative;
}
.tabsWrappers {
float: none;
width: 100%;
max-width: 75%;
margin: 15px auto 0;
display: table;
padding: 0px 60px;
position: relative;
border: 1px solid #eee;
border-top: 0px;
border-bottom: 0px;
}
.tabcontent .ScrollbarsWrapper .view {
margin-bottom: auto !important;
}
.tabcontent {
float: left;
width: 100%;
height: calc(100vh - 185px);
position: relative;
}
.tabcontent .row {
margin: 0 auto;
}
.tabsWrapper .row {
float: left;
width: 100%;
}
.deactivatewrapper {
float: left;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
}
.card-header-New .btn {
width: auto;
font-size: 14px;
margin-left: 10px;
}
.deactivatewrapper button {width: 33%;background: transparent;border: 0px;font-size: 15px;color: #c54646;font-weight: 500;}
.cmnfilterHeadbtn, .cmnfilterHeadbtn:focus, .cmnfilterHeadbtn:active {
background-color: #c0422f !important;
border-color: #c0422f !important;
}
.cmnfilterHeadbtn:hover {
background-color: #333 !important;
border-color: #333 !important;
}
.form-check-input:checked {
background-color: #c0422f;
border-color: #c0422f;
}
.cmnfilterHeadbtn img {
width: 15px;
margin: -2px 1px 0px 0px;
filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(40deg) brightness(102%) contrast(101%);
}
.deactivatewrapper h4 {
font-weight: 400;
font-size: 15px;
line-height: 23px;
width: 75%;
}
.tabcontentwraps {
height: calc(100vh - 240px);
}
.tabsWrappersset {
float: left;
width: 100%;
height: 100%;
}
.tabcomnsection ul {
padding: 0px;
margin: 0px;
}
.tabheadebuttns {
float: left;
width: auto;
display: flex;
position: absolute;
right: 0;
top: -8px;
column-gap: 7px;
}
.tabheadebuttns .btn {
padding: 6px 10px;
font-size: 13px;
width: auto;
}
.tabheadebuttns .btn.btn-link {
background: transparent;
color: #333;
}
.tabsWrapperset {
float: left;
width: 100%;
position: relative;
}
.formloginset.formeditset .col-md-3 {padding: 0px;}
.formloginset.formeditset h4 {font-size: 18px;margin: 0px 0 10px;}
.formloginset.formeditset .formgroups p {font-size: 14px;width: 85%;float: left;} 
.coverUploadImage {
float: left;
width: 100%;
height: 260px;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
border: 1px dotted #adafb1;
text-align: center;
padding: 10px;
box-sizing: border-box;
cursor: pointer;
}
.coverUploadImageSet .uplodimgicon {
width: 29px;
margin-bottom: 11px;
}
.coverUploadImage .uplodedimageset {
float: left;
width: 100%;
height: 100%;
object-fit: contain;
background-color: #f6f6f6;
object-position: center;
}
.coverUploadImageSet h4 {
font-size: 14px !important;
color: #8e8e8e;
font-weight: 400;
margin: 0px !important;
}
.uploadeyoutubevideo {
float: left;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
}
.formloginset.formeditset .row {
float: left;
width: 100%;
margin-bottom: 20px;
}
.ammountfield {
float: left;
width: 100%;
position: relative;
}
.ammountfield input {
padding-left: 50px;
}
.ammountfield span {
float: left;
width: 40px;
height: 100%;
font-size: 18px;
display: flex;
justify-content: center;
align-items: center;
background-color: #eaeaea;
position: absolute;
left: 0;
top: 0;
}
.titlewithcount {
float: left;
width: 100%;
position: relative;
}
.titlewithcount .counttitle {
float: left;
width: 24px;
position: absolute;
right: 8px;
top: 9px;
padding: 1px 5px;
font-weight: 500;
background-color: #eaeaea;
border-radius: 100%;
font-size: 13px;
display: flex;
justify-content: center;
align-items: center;
}
/* common tabs section end here */

/* editor css start here */
.editorBox .ql-toolbar.ql-snow .ql-formats {
width: auto;
}
.editorBox .ql-container .ql-editor {
min-height: 200px;
max-height: 200px;
overflow: overlay;
width: 100% !important;
}
.descriptiontable {
text-align: justify;
}
.descriptiontable img, .descriptiontable .ql-video {
width: auto;
padding-bottom: 15px;
}
.descriptionview p {
float: left;
width: 100%;
}
.ql-editor li:not(.ql-direction-rtl)::before {
width: auto;
float: left;
}
.ql-tooltip.ql-editing,
.ql-tooltip.ql-editing .ql-preview, 
.ql-tooltip.ql-editing input[type=text],
.ql-tooltip.ql-editing .ql-action {
width: auto;
  float: none;
}
.ql-tooltip,
.ql-tooltip .ql-preview, 
.ql-tooltip input[type=text],
.ql-tooltip .ql-action,
.ql-tooltip .ql-remove {
width: auto;
  float: none;
}
.editorBox {
float: left;
width: 100%;
}
.ql-toolbar.ql-snow .ql-image {
width: 40px;
}
.formsectionset .col-md-3 {
/* padding-left: 0px; */
}
ul.mainTabs {
float: left;
width: 100%;
margin: 0;
padding: 0px;
display: flex;
column-gap: 7px;
justify-content: space-between;
margin-bottom: 20px;
}
ul.mainTabs li {
width: 100%;
list-style: none;
padding: 8px 10px;
font-size: 15px;
cursor: pointer;
text-align: center;
background-color: #eee;
}
ul.mainTabs li.active {
background-color: #c0422f;
color: #fff;
}
.tabcontentnew {
float: left;
width: 100%;
/* height: calc(100vh - 245px); */
position: relative;
}
/* editor css end here */

/* avatar css start here */
.avatar {
width: auto;
float: left;
}
.avatar .userprofile {
float: left;
width: 45px;
height: 45px;
object-fit: contain;
object-position: center;
background-color: #f7f7f7;
border-radius: 4px;
}
/* avatar css end here */

/* fundraise css start here */
.funderviewer {float: left;width: 100%;position: relative;}
.funderviewer h1 {font-size: 23px;margin: 2px 0 17px;font-weight: 700;position: relative;}
.fundraiserDetails {
float: left;
width: 100%;
margin: 15px 0 15px;
border-bottom: 1px solid #ddd;
padding-bottom: 15px;
display: flex;
justify-content: flex-start;
align-items: center;
}
.fundraiserDetails .avatar {width: auto;margin: 0px 12px 0 0;position: relative;}
.fundraiserDetails .avatar img {width: 39px;height: 39px;background: transparent;}
.userdetails {float: left;width: auto;}
.userdetails h5 {font-size: 16px;margin: 0 0 0px;font-weight: 400;}
.userdetails h5 span {
  font-weight: 500;
}
.fundraiseCharitys {
float: left;
width: 100%;
margin: 0px 0 15px;
border-bottom: 1px solid #ddd;
padding-bottom: 15px;
display: flex;
justify-content: flex-start;
align-items: center;
}
.fundraiseCharitys span {font-size: 14px;color: #838383;margin-right: 15px;}
.fundraiseCharitys .categorytitle {
font-size: 14px;
text-transform: capitalize;
display: list-item;
margin-left: 15px;
padding-left: 5px;
}
.fundraiseCharitys .categorytitle img {
width: 20px;
margin: 0 10px 0 0;
filter: invert(46%) sepia(86%) saturate(2499%) hue-rotate(220deg) brightness(98%) contrast(99%);
}
.descriptionview {
float: left;
width: 100%;
padding: 0 0 12px;
border-bottom: 1px solid #ddd;
margin-bottom: 12px;
}
.bannerfundraise {
float: left;
width: 100%;
}
.bannerfundraise img {float: left;width: 100%;border-radius: 10px;}
.bannerfundraise iframe {float: left;width: 100%;height: 350px;border-radius: 10px;}
.formsetting {
float: left;
width: 100%;
padding: 0px;
margin: 0;
}
.formsetting li {
float: left;
width: 100%;
display: flex;
justify-content: space-between;
padding: 13px 0px;
margin-bottom: 0px;
border-bottom: 1px solid #ddd;
align-items: center;
}
.formsetting li h4 {
font-size: 15px;
margin: 0px;
font-weight: 400;
}
.uploadcoverMediabtn {
width: auto !important;
padding: 8px 13px !important;
font-size: 14px;
background-color: transparent !important;
color: #c0422f;
border-color: #c0422f;
border-radius: 7px !important;
line-height: normal;
}
.uploadcoverMediabtn img {
width: 19px;
margin: 0 9px 0 0;
filter: invert(33%) sepia(36%) saturate(2262%) hue-rotate(335deg) brightness(90%) contrast(92%);
}
.uploadcoverMediabtn:hover {
background-color: #c0422f !important;
}
.uploadcoverMediabtn:hover  img{
filter: invert(100%) sepia(8%) saturate(355%) hue-rotate(348deg) brightness(116%) contrast(99%);
}
/* fundraise end start here */

/* image upload  */
.uploadfielsbuttons .uploadfilebuttons {margin-bottom: 17px;}
.imageuploads {float: left;width: 100px;height: 100px;border-radius: 20px;position: relative;}
.imageuploads.bg-grey img{background-color: #d5d5d5;object-fit: contain;}
.imageuploads img {float: left;width: 100%;height: 100%;border-radius: 20px;object-fit: cover;object-position: center;}
.imageuploads .closeIcon {float: left;width: 17px;height: 17px;position: absolute;right: -2px;top: -2px;cursor: pointer;background-color: #fff;border-radius: 100%;}
.imageuploads .closeIcon::after {content: "";float: left;height: 100%;width: 100%;-webkit-mask-image: url("../img/closebutton.png"); mask-image: url("../img/closebutton.png"); mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: red !important;}
.fileloader {float: left;width: 28px;height: 28px;position: absolute;right: -6px;background-color: #fff;border-radius: 100%;top: -5px;padding: 5px;box-shadow: 0px 0px 17px -3px #ccc;}
.fileloader img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.fileloader.active {padding: 0px;}
.uploadbuttons {float: left;width: 100px;height: 100px;position: relative;cursor: pointer;}
.uploadbuttons .uploadfilebox img {float: left;height: 100%;width: 100%; border-radius: 20px;}
.uploadbuttons .fileloader input.uploadfilInput {position: absolute;visibility: hidden;height: 100%;width: 100%;left: 0;top: 0;}
.uploadbuttons .fileloader label {float: left;width: 100%;height: 100%;cursor: pointer;}
.uploadbuttons .fileloader label img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
/* image upload  */

/* upload media modal css */
.uploadfielsbuttons {float: left;width: 100%;}
.uploadfielsbuttons .btn {border-radius: 6px;width: 100%;float: left;background-color: #fafafa !important;border: 1px solid #ddd !important;margin-bottom: 10px;color: #363636 !important;padding: 10px 10px;font-size: 15px;}
.uploadfielsbuttons .btn .uploadIcon {width: 23px;margin: 0 10px 0 0;}
.uploadfilebuttons {position: relative;}
.uploadfilebuttons input[type="file"] {float: left;width: 100%;position: absolute;left: 0;height: 100%;top: 0;visibility: hidden;}
.uploadLoaderIcon {width: 40px;position: absolute;right: 0;top: 2px;}
.addvideoform {float: left;width: 100%;padding: 12px 10px;border: 1px solid #ddd;border-radius: 6px;}
.addvideoform input {height: 41px;box-shadow: none !important;margin-bottom: 10px;font-size: 14px;}
.addvideoform .btn {padding: 10px 10px;font-size: 14px;margin: 0px !important;background-color: #c0422f !important;color: #fff !important;}
.addvideoform .mb-3 {margin: 0px !important;}
.addvideoform .danger-colorset {margin: 0px 0 10px;}
/* upload media modal css */

/* fundraise comment sections */
.updatedescriotionview {float: left;width: 100%;position: relative;margin-bottom: 10px;}
.updatedescriotionview h4 {font-size: 18px;font-weight: 700;}
.updatedescriotionview .updatecommentpass {font-size: 14px;margin-bottom: 25px;}
.updatecomment {float: left;width: 100%;padding: 0px;margin: 0px;}
.updatecomment li {float: left;width: 100%;list-style: none;display: flex;justify-content: flex-start;align-items: flex-start;margin-bottom: 25px;position: relative;}
.updatecomment li .avatar img {width: 40px;height: 40px;}
.commtsdetail {float: left;width: 100%;flex-grow: 1;}
.commtsdetail h3 {font-size: 15px;font-weight: 600;margin: 0 0 10px 0px;}
.commtsdetail h3 small {font-weight: 400;color: #333;}
.fundraisedonatswrps .updatecomment li {border-bottom: 1px solid #e6e6e6;padding-bottom: 15px;margin-bottom: 15px;}
.fundraisedonatswrps .updatecomment li:last-child {border-bottom: 0px;padding-bottom: 0px;} 
.fundraisedonatswrps .updatecomment li .commtsdetail p b {float: left;width: auto;}
.fundraisedonatswrps .updatecomment li .commtsdetail p span {display: list-item;list-style: disc;float: right;width: auto;margin-left: 25px;font-size: 12px;text-decoration: underline;}
.updatecomment li .avatar {margin: 0 15px 0 0;}
.fundraisedonatswrps {float: left;width: 100%;box-shadow: 0 0.3125rem 1rem -0.1875rem rgba(0,0,0,.2);padding: 15px 15px;border-radius: 10px;position: -webkit-sticky;position: sticky;top: 1rem;}
.seeallbtn, .seeallbtn:focus {padding: 7px 15px;width: 100%;background: transparent;color: #c0422f;border-color: #c0422f;font-size: 14px;}
.seeallbtn:hover {background-color: #c0422f;color: #fff;}
.progressAmount {float: left;width: 100%;margin-bottom: 15px;border-bottom: 1px solid #e6e6e6;padding-bottom: 15px;}
.progressAmount h5 {font-size: 14px;font-weight: 400;line-height: 28px;}
.progressAmount h5 span {width: auto;float: left;font-size: 24px;font-weight: 600;margin: 0 6px 0 0;}
.progressAmount .progressbar-ammounts div:first-child {height: 11px !important;}
.progressAmount .progressbar-ammounts div span {display: none !important;}
.progressAmount small {color: #626262;font-size: 13px;margin: 7px 0 0;float: left;width: 100%;}
.charityName {
float: left;
width: 100%;
font-size: 14px;
background: #f6f6f6;
margin: 0 0 10px;
padding: 12px 10px;
border-radius: 6px;
word-break: break-all;
}
.viewCompaignTitlte {
  float: left;
  width: 100%;
  font-size: 21px;
  font-weight: 700;
  margin: 15px 0 25px;
  position: relative;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}
.viewCompaignTitlte::after {
  content: "";
  float: left;
  width: 83px;
  height: 4px;
  background-color: #c0422f;
  position: absolute;
  left: 0;
  bottom: -1px;
}
/* fundraise comment sections */

/* common loader css start here */
.commonLoader {float: left;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;position: absolute;left: 0;top: 0;background-color: rgba(255,255,255,0.9);}
.commonLoader.fundraiseLoader {height: calc(100% - 104px);top: 104px;}
.commonLoader .loader {border: 4px solid #333;border-radius: 100%;border-top: 4px solid transparent;width: 70px;height: 70px;-webkit-animation: spin 2s linear infinite; /* Safari */animation: spin 2s linear infinite;}
.commonLoader.charityLoader {height: calc(100% - 45px);top: 45px;}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* common loader css end here */

/* pagination css start here */
.paginationCustom {float: left;width: 100%;padding: 0px;margin: 9px 0px 0px;}
.paginationCustom li:first-child, .paginationCustom li:last-child {display: none;}
.paginationCustom li .rpb-item--active {background-color: #c0422f;}
.paginationCustom li button {width: 29px;height: 29px;min-width: 29px;font-size: 14px;border-radius: 100%;}
/* pagination css end here */

/* tab head css start here */
.tabheadse {float: left;width: 100%;margin: 0 0 5px;text-align: right;}
.tabheadse .btn {padding: 6px 10px;font-size: 13px;text-decoration: underline;float: right;color: #0d6efd;height: auto;width: auto;background: transparent !important;}
.linksmanage {color: #0d6efd !important;text-decoration: underline;outline: none !important;border: 0px !important;}
/* tab head css end here */

.deletUpdates {float: left;width: 20px;position: absolute;top: 0;right: 20px;}
.deletUpdates #dropdown-basic {background-color: transparent !important;border: 0px !important;width: 17px;padding: 0px;display: flex;align-items: center;}
.deletUpdates #dropdown-basic img {width: 100%;}
.deletUpdates .dropdown-menu {right: 0 !important;left: auto !important;padding: 0px;border-radius: 0px;}
.deletUpdates #dropdown-basic img {width: 100%;}
.deletUpdates .dropdown-menu a.dropdown-item {font-size: 13px;padding: 8px 15px;display: flex;align-items: center;}
.deletUpdates .dropdown-menu a.dropdown-item img {width: 14px;margin: 0 7px 0 0;}
.modal-header .btn-close {box-shadow: none !important;}
.commtsdetail .updatedImage {
width: 200px;
margin: 0 0 5px 0;
border-radius: 10px;
}
.viewmoreUpdatescheck {
float: left;
width: 100%;
font-size: 15px;
background: linear-gradient(0deg, #fff 41px, transparent 66px);
height: 61px;
position: absolute;
left: 0;
bottom: 0;
display: flex;
justify-content: center;
cursor: pointer;
align-items: center;
color: #c0422f;
}
.maintabcontent {
float: left;
width: 100%;
position: relative;
}
.newswitchbtn input{
height: 1rem;
font-size: initial;
}

.tabheadebuttnsnew {
float: left;
width: auto;
}
.tabheadebuttnsnew .btn {
padding: 6px 10px;
font-size: 13px;
width: auto;
margin: 0px !important;
}
.tabheadebuttnsnew .btn.btn-link {
background: transparent;
color: #333;
}


/* custom radio button css  */
.selectfundraisefor {
float: left;
width: 100%;
margin-bottom: 15px;
display: flex;
align-items: center;
}
.radiocustom {
font-size: large;
width: 100%;
display: flex;
ALIGN-ITEMS: center;
margin: 0px;
padding: 0px;
}
.radiocustom li {
float: left;
width: auto;
list-style: none;
margin-right: 21px;
position: relative;
padding-left: 29px;
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 17px;
cursor: pointer;
}
.radiocustom li::after {
content: "";
width: 22px;
height: 22px;
border: 1px solid #ddd;
border-radius: 100%;
background: #fff;
float: left;
position: absolute;
left: 0;
top: 4px;
}
.radiocustom li.active::before {
content: "";
float: left;
z-index: 9;
width: 14px;
height: 14px;
background: #c0422f;
position: absolute;
top: 8px;
left: 4px;
border-radius: 100%;
}
/* custom radio button css  */

.nodonations {
float: left;
width: 100%;
font-size: 14px;
color: #646464;
text-align: center;
}


.dashboardBlogs .activeunactiovdiv {
float: left;
width: auto;
line-height: normal;
padding: 5px 8px;
background-color: #fff;
font-size: 13px;
border-radius: 7px;
display: flex;
flex-direction: column;
justify-content: flex-start;
}
.donationDshboard {
float: left;
width: 100%;
position: relative;
margin-bottom: 10px;
}
.dahsbordTablecol {float: left;width: 49.33%;border: 1px solid #EFEEEC;border-radius: 10px;padding: 15px 15px;margin-bottom: 15px;position: relative;}
.dahsbordTablecol h4 {
float: left;
width: 100%;
font-size: 15px;
font-weight: 600;
margin: 0 0 11px;
}
.tableswrps {
float: left;
width: 100%;
height: 320px;
position: relative;
}
.piechartwraps {
float: left;
width: 100%;
position: relative;
}
.activeunactiovdiv div {
float: left;
width: 100%;
display: inline;
color: #333;
font-weight: 600;
font-size: 15px;
margin: 2px 0 0;
word-break: break-word;
}
.activdeaciwrapper {
float: left;
width: 100%;
column-gap: 5px;
display: flex;
}
.dashboardBlogs.gradient-1 .activeunactiovdiv {
color: #843cf6;
}
.dashboardBlogs.gradient-2 .activeunactiovdiv {
color: #fc5286;
}
.dashboardBlogs.gradient-3 .activeunactiovdiv {
color: #ff763b;
}
.dashboardBlogs.gradient-4 .activeunactiovdiv {
color: #0e4cfd;
}
.dashboardBlogs.gradient-5 .activeunactiovdiv {
color: #843cf6;
}
.dashboardBlogs.gradient-6 .activeunactiovdiv {
color: #b28900;
}
.dashboardBlogs.gradient-7 .activeunactiovdiv {
color: #357a38;
}
.dashboardBlogs.gradient-8 .activeunactiovdiv {
color: #b23c17;
}
/* #columnchart_material svg, #columnchart_material svg g,#columnchart_material div:first-child {
  float: left;
  width: 100% !important;
}
#columnchart_material_New svg, #columnchart_material_New svg g, #columnchart_material_New div:first-child {
  float: left;
  width: 100% !important;
}  */
.colsrow {
float: left;
width: 100%;
column-gap: 14px;
display: flex;
position: relative;
}
.divider-Header h3 {
  float: left;
  width: 100%;
  margin: 30px 0 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  font-size: 22px;
  position: relative;
}
/* .divider-Header h3::after {
  content: "";
  float: left;
  width: 83px;
  height: 3px;
  background-color: #c0422f;
  position: absolute;
  left: 0;
  bottom: 0;
} */
.youtube_link{height: 150px;width: 225px;display: flex;align-items: center;position: relative;justify-content: center;background-color: #c1c1c1;border-radius: 10px;font-size: 25px;font-weight: 600;line-height: 1;padding: 0px;}
.youtube_link span{height: 30px;width: 30px;content: "";position: absolute;vertical-align: middle;text-align: center;background-color: #f1f1f1;border-radius: 5px;cursor: pointer;}
.youtube_link-wrapper{max-width: 225px;width: 100%;z-index: 1;position: relative;}
.youtube_link-wrapper .close-button{position: absolute;right: -5px;top: -5px;height: 20px;width: 20px;background-color: white!important;padding: 0px!important;margin: 0px;display: flex;align-items: center;justify-content: center;border-radius: 40px;border-width: 0px;}
.youtube_link-wrapper .close-button i{fill: red;}
.label-required::after{content: "*";display: inline-block;padding-left: 4px;font-size: 15px;color: red;}
h4.label-required::after{vertical-align: 3px;}

.draggable-table .draggable-table__drag {
  font-size: 0.95em;
  position: absolute;
  width: 100%;
  text-indent: 50px;
  border: 1px solid #f1f1f1;
  z-index: 10;
  cursor: grabbing;
  -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
  opacity: 1;
}
.draggable-table thead th {
  height: 25px;
  font-weight: bold;
  text-transform: capitalize;
  padding: 10px;
  user-select: none;
}
.draggable-table tbody tr {
  cursor: grabbing;
}
.draggable-table tbody tr td {
  text-transform: capitalize;
  text-indent: 50px;
  padding: 10px;
  user-select: none;
  border-top: 1px solid whitesmoke;
}
.draggable-table tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}
.draggable-table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}
.draggable-table tbody tr.is-dragging {
  background: #f1c40f;
}
.draggable-table tbody tr.is-dragging td {
  color: #ffe683;
}
.select-custom{width: 180px}
.time-picker-input input{border: 1px solid #ddd !important;padding: .375rem .75rem .375rem 30px!important;font-size: 14px;line-height: 1.6;width: 134px;}
.time-picker-input input:focus{outline: none!important;}
.time-picker-input input::placeholder{
  font-size: 12px;
  letter-spacing: 1px;
}
.time-picker-input input:not(:last-child){
  padding-right: 24px!important
}
.time-picker-input .react-datepicker__close-icon{
  display: inline-flex;
  align-items: center;
}
.time-picker-input .react-datepicker__close-icon::after{
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  display: inline-flex;
  content: "×";
  line-height: 0.8;
  justify-content: center;
}
.image_table{max-width: 60px;max-height: 60px;height: 100%;width: 100%;object-fit: cover;object-position: center;border-radius: 2px;}
.text-capitalized{text-transform: capitalize;}
button.btn-sm{font-size: 12px;}
.successpymentwrapper{
  font-family: var(--bs-body-font-family);
}
.successpymentwrapper h4, .pop-up h2, .pop-up h3, .pop-up h4 {
  float: left;
  width: 100%;
  font-size: 30px;
  margin: 17px 0 10px;
  position: relative;
}
.successpymentwrapper p{
  float: none;
  margin: 0px auto 16px;
  display: table;
  font-size: 17px;
  line-height: 29px;
  color: #949494;
  }
  .successpymentwrapper p b, .successpymentwrapper p strong {
    color: #333;
  }
  .succesiocn {
  float: left;
  width: 105px;
  height: 105px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -53px;
  z-index: 9;
  overflow: hidden;
  }
  .icon-span{display: flex;align-items: center;justify-content: center;}
  .icon-span img{height: 100%;width: 100%;}
.time-picker-input .react-datepicker__input-container{display: flex;align-items: center;}
tr.bg-success{
  --bs-success-rgb: 207, 255, 218
}
tr.bg-info{
  --bs-info-rgb: 212, 233, 255
}
tr.bg-warning{
  --bs-warning-rgb: 255, 243, 205
}
tr.bg-info-1.bg-info{
  --bs-info-rgb: 255, 232, 228
}
.modal-preview {
  --bs-modal-width: 100%;
  --bs-modal-border-color: #ffffff;
}
.modal-preview .modal-dialog{
  height: calc(100vh);
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 14px 14px;
}
.modal-preview .modal-dialog .modal-content{height: 100%;--bs-modal-bg: #ffffff; overflow: hidden;border-radius: 0.5rem;}
.modal-preview .modal-dialog .modal-content .modal-body{
  max-height: calc(100vh - 30px);
  height: 100%;
  overflow: hidden;
}
.modal-preview .modal-dialog .modal-content .modal-body .row, .modal-preview .modal-dialog .modal-content .modal-body .row .col-6{height: 100%;}
.modal-preview .modal-dialog .btn-close{position: absolute;right: 10px;top: 10px;cursor: pointer;z-index: 1;}
/* .modal-preview .image-preview{max-width: 100%;max-height: calc(100% - 20px);margin: auto auto;} */
.modal-preview .modal-dialog .modal-content .modal-body .row .col-6{display: flex;flex-direction: column;}
.fade.mosaic-modal.show .commonLoader.charityLoader{background-color: transparent;top:0px}
/* .formsectionset input{
  border-radius: 0px !important;
} */
.formsectionset .form-select{
  border-radius: 0px !important;
}
.newClass {
  margin-left: 20px;
}
.blog-details, .blog-details .ql-container {line-height: 1.5;color: #333437;}
.blog-details p{margin-bottom: 15px!important;}
.blog-details p img{width: 100%;margin-bottom: 15px!important;}
.blog-details h1{font-size: 29px!important;margin-bottom: 15px!important;}
.blog-details h2{font-size: 26px!important;margin-bottom: 15px!important;}
.blog-details h3{font-size: 22px!important;margin-bottom: 15px!important;}
.blog_details h4 {font-size: 20px !important;margin-bottom: 15px!important;}
.blog_details h3 {font-size: 18px!important;margin-bottom: 15px!important;color: #000;}
.blog_details .ql-editor{
  max-height: 400px;
}
.penigantatain_wrapper{
  position: relative;
  display: inline-block;

}

.penigantatain_wrapper .peginataion_input{
  width: 70px;
    padding: 5px;
    border-color: #ddd;
    outline: none;
    border: 1px solid #ddd;
}
.penigantatain_wrapper .peginatation_close_btn{
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
  border: none;
  font-size: 10px;
  cursor: pointer;
  background-color: red;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  color: white;

}
.Multiple_Export_wrapper {
  position: absolute;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  background: white; 
  padding: 10px;
  margin: 5px;
  top: 50px;
  right: 0px;
  border-radius: 10px;
  gap: 1rem;
  box-shadow: 0px 4px 10px rgba(192, 66, 47, 0.5); /* Added box-shadow */
}

.Multiple_Export_wrapper button{
  outline: none !important;
  background-color: transparent;
  border: none !important;
  /* color: #c0422f;  */
}
@media (max-width: 1400px) {
  .select-custom{width: 160px;}
  
}
.toolktis {
  position: relative;
  float: right;
  width: auto;
  }
  .infostatus {
  float: left;
  width: auto;
  }
  .toolktis .erroMessagwrapper {
  float: left;
  width: auto;
  margin: 0 0 0 5px;
  }
  .toolktis .erroMessagwrapper svg {
  height: 19px;
  width: 19px;
  cursor: pointer;
  }
  .toolktis .tooltpimessae {
  float: left;
  width: 181px;
  position: absolute;
  top: -25px;
  right: -170px;
  background-color: #333;
  color: #fff;
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 7px;
  left: auto;
  display: none;
  }
  .toolktis:hover .tooltpimessae {
  display: block;
  }
  .only_show_mosaic{
    display: flex;
    width: 100%;
    justify-items: center;
    align-items: center;
    flex-direction: column;
  }

  .text-custom {
    color: #464a53 !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.transition-transform {
  transition: transform 0.3s ease !important;
}

.new-arrow-class{
  height: 15px !important;
  width: 15px !important;
  margin: 0 12px 0px 0px;
}
.new_spinner_classname{
  width: 14px !important;
  height:14px !important;
  margin-left: 5px !important;
}

.button-class-name{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  outline: none;
  border: none;
  background: gray;
  border-radius: 5px;
  color: white;
  text-transform: capitalize;
  cursor: auto;
}

.size-16 {
  width: 25px;
  height: 25px;
}

.notification-count{
  color: #c0422f;
  border: 1px solid #c0422f;
  border-radius: 50px;
  padding: 5px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.bell-class{
  background: transparent;
  outline: none;
  border: none;
  height: 35px;
}

.bell-class:hover{
  background-color: white !important;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
  background-color: transparent !important;
}
.dropdown-style{
      display: flex;
      justify-content: space-between;
      gap: 10px;
}


.mosaic-img-box{
  height: 60px;
  width: 70px;
  /* cursor: pointer; */
}

.inside-image-class{
  height: 100%  ;
  width: 100%;
  object-fit: contain;
}


.badge.bg-pending {
  background-color: #6c757d;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 10px;
  }
.badge.bg-review {
  background-color: #e5b177;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 10px;
  }
.badge.bg-complete {
  background-color: #007AFF;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 10px;
  }
.badge.bg-approved {
  background-color: #A4C0AA;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 10px;
  }
.badge.bg-rejected {
  background-color: #C34330;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 10px;
  }
.badge.bg-requested {
  background-color: #AF52DE;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 10px;
  }

  .badge.bg-delete {
    background-color:#6f4070;;
    color: white;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 10px;
  }
  
  .text-capitalize{
    text-transform: capitalize !important;
  }
  .font-size{
    font-size: 14px !important;
  }

  .p-0{
    padding: 0px !important;
  }
 .multiple-input-width {
  width: 80px;
  }
  .react-datepicker__close-icon::after{
    background-color:black !important;
  }

  .video-remove{
    font-size: 14px !important;
    padding: 0px !important;
    text-align: center !important;
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px !important;
  }